body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

h1,h2,h3,h4,h5 {
  font-weight: 400;
}

p {
  color: #333;
}

.wrapper {
  margin: 0 auto;
  min-height: 100vh;
  height: 100%;
  max-width: 800px;
  background: white;
}

.experienceHeader {
  background: #E2C04C;
  color: white;
  width: 100%;
  height: 200px;
  margin-bottom: 25px;
}

.highlight {
  color: #E2C04C;
}

.absolute {
  position: absolute;
}

.swipeCard {
  border: 3px solid #333;
  border-radius: 8px;
  height: 500px;
  width: 355px;
  background-size: contain;
  background-repeat: no-repeat;
}

.btn {
  width: 100%;
  color: white;
  height: 100%;
  font-size: 18px;
  text-transform: capitalize;
  background: #8AC1EA;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: background 0.1s ease;
  border-radius: 4px;
}

.btn:hover {
  background: #65b6f3;
}

.backBtn {
  opacity: 0.9;
  position: fixed;
  bottom: 15px;
  left: 15px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #8AC1EA;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo img {
  max-width: 80px;
}

.reading-font {
  font-family: "Merriweather";
}